import React from "react";
import { Stat } from "./Stat";

export const Statistics = ({ StatisticsSection }) => {
  return (
    <>
      <div className="w-12/12 md:w-5/12 mt-32 flex m-auto flex-col justify-center rounded-2xl">
        {/* الصف الأول */}
        <div className="lg:flex mx-4 gap-8 mb-8">
          <Stat
            title={StatisticsSection.Stat[0].Title}
            desc={StatisticsSection.Stat[0].description}
            number={StatisticsSection.Stat[0].stat + "%"}
          />
          <Stat
            title={StatisticsSection.Stat[1].Title}
            desc={StatisticsSection.Stat[1].description}
            number={StatisticsSection.Stat[1].stat + "%"}
          />
        </div>

        {/* الصف الثاني */}
        <div className="lg:flex mx-4 gap-8">
          <Stat
            title={StatisticsSection.Stat[2].Title}
            desc={StatisticsSection.Stat[2].description}
            number={StatisticsSection.Stat[2].stat + "%"}
          />
          <Stat
            title={StatisticsSection.Stat[3].Title}
            desc={StatisticsSection.Stat[3].description}
            number={StatisticsSection.Stat[3].stat + "%"}
          />
        </div>
      </div>
    </>
  );
};
